import { useState, useContext, useRef, useEffect } from "react"
import { LeftCircleFilled, ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import InfiniteScroll from "react-awesome-infinite-scroll";
import Nodata from "@/components/Nodata";
import Loading from "@/components/Loading";
import { GlobalContext } from "@/GlobalContext";
import { Segmented, Input, message, Tabs, Tooltip } from 'antd';
import { useNavigate } from "react-router";
import { getSearchList, getSearchItemDetail, recommendArticleSplit, getSplit, articleSplit,searchImportResource } from "@/pages/Creation/service"
import styles from "./index.module.less"
const { TabPane } = Tabs;

/**
 * 创作中心搜索资源库
 * @param
 * @returns 
 */

export default function EditorMaterial(
    {
        getEditor,
        getEditorContents,
        setEditorContents,
        getEditorSelection,
        setTextSelection,
        setTextReplace,
        templateId,
        // SaveComponent //保存组件
    }
) {
    const { state: { dict: { searchType } } } = useContext(GlobalContext)
    const Navigate = useNavigate();
    const [detailParams, setDetailParams] = useState({ id: undefined, scope: undefined })
    const [list, setList] = useState([]);
    const [total, setTotal] = useState(0)
    const [detail, setDetail] = useState<any>()
    const listWrapper: any = useRef();
    const [detailOpen, setDetailOpen] = useState(false)
    const [params, setParams] = useState({
        scope: '0',
        keyWord: '',
        page: 1,
        size: 20,
        templateId: null
    })
    const [hasMore, setHasMore] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    // 切换搜索类别
    const onChange = async (val) => {
        const newParams = { ...params, scope: val, page: 1 }
        setParams({ ...newParams })
        setList([])
        setDetailOpen(false)
        if (params.keyWord) {
            setLoading(true);
            if (listWrapper.current) listWrapper.current.scrollTop = 0;
            try {
                const response = await getSearchList({ ...newParams, });
                const { code, msg, data } = response.data;
                setLoading(false);
                if (code === 200) {
                    if (data?.rows?.length) {
                        setTotal(data.total);
                        setList(data.rows)
                        setHasMore(Math.ceil(data.total / data.pageSize) > data.pageNum);
                    }
                }
            } catch (error) { }
        }

    }

    const onInputSearchText = (e) => {
        setParams({ ...params, keyWord: e?.target?.value })
    }

    /**
     * 搜索
     * @returns 
     */
    const handleSearch = async () => {
        if (!params.keyWord) return;
        setParams({ ...params, templateId, page: 1 })
        if (!list.length) setLoading(true);
        setDetailOpen(false);
        if (listWrapper.current) listWrapper.current.scrollTop = 0;
        try {
            const response = await getSearchList({ ...params, });
            const { code, msg, data } = response.data;
            setLoading(false);
            if (code === 200) {
                if (data?.rows?.length) {
                    setTotal(data.total);
                    setList(data.rows)
                    setHasMore(Math.ceil(data.total / data.pageSize) > data.pageNum);
                } else {
                    setTotal(data.total);
                    setList(data.rows)
                    setHasMore(false);
                }
            }
        } catch (error) { }
    }
    /**
     * next list
     * @param params 
     */
    const fetchList = async (params) => {
        try {
            const response = await getSearchList(params);
            const { code, msg, data } = response.data;

            if (code === 200) {
                if (data.rows?.length) {
                    const next = list.concat(data.rows);
                    setList(next)
                    setTotal(data.total)
                    setParams({ ...params, page: data.pageNum })
                    setHasMore(Math.ceil(data.total / data.pageSize) > data.pageNum);
                } else {
                    setHasMore(false);
                }
            } else {
                message.warning(msg);
            }
        } catch (error) {
            message.warning(error);
        }
    };

    const onClickItem = async (cb_params) => {

        try {
            if (cb_params.sketch) {
                const response = await articleSplit({ id: cb_params.searchId })
                const { code, msg, data } = response.data;
                if (code === 200) {
                    setDetail({
                        ...data,
                        paragraphList: data?.paragraphList?.filter(item => item?.trim()?.length)
                    })
                    setDetailOpen(true)
                    if (listWrapper.current) listWrapper.current.scrollTop = 0;
                } else {
                    message.error(msg)
                }
            } else {
                const response = await getSplit({ ...cb_params })
                const { code, msg, data } = response.data;
                if (code === 200) {
                    setDetail({
                        ...data,
                        paragraphList: data?.paragraphList?.filter(item => item?.trim()?.length)
                    })
                    setDetailOpen(true)
                } else {
                    message.error(msg)
                }
            }

        } catch (error) {
            message.error(error)
        }

    }

    const renderList = (scope) => {
        if (scope == '2') {
            return <div style={{ height: '100%' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
                    <ArrowLeftOutlined onClick={()=>Navigate(-1)} />
                    <ArrowRightOutlined onClick={()=>Navigate(1)} />
                </div>
                <iframe src="https://wap.sogou.com/" style={{ width: '100%', height: '100%', border: 'unset' }}></iframe>
            </div>
        }
        else {
            return (
                loading ? <Loading /> :
                    <InfiniteScroll
                        length={list?.length}
                        next={() => fetchList({ ...params, page: params.page + 1 })}
                        scrollableParent={document.querySelector(".listWrapper")}
                        hasMore={hasMore}
                        isRefreshing={false}
                        pullDownToRefresh={false}
                        pullDownComponent={<div style={{ height: "50px" }}>下拉</div>}
                        releaseComponent={<div style={{ height: "50px" }}>释放</div>}
                        refreshingComponent={<div style={{ height: "50px" }}>加载中</div>}
                        refreshEndComponent={<div style={{ height: "50px" }}>加载完成</div>}
                        loadingComponent={<Loading />}
                        endComponent={
                            list.length ? hasMore ? null :
                                (<div style={{ textAlign: "center", fontWeight: "normal", color: "#999" }}>
                                    <span>没有更多内容了</span>
                                </div>
                                ) : null
                        }
                    >
                        {
                            list.length ? list?.map((item, index) => (
                                (params.scope !== "2") ? (params.scope === "3") ? <TimelineItem3
                                    {...item}
                                    key={index}
                                    scope={params?.scope}
                                    setDetailOpen={setDetailOpen}
                                    onClick={onClickItem}
                                    setDetailParams={setDetailParams}
                                /> : <TimelineItem
                                    {...item}
                                    key={index}
                                    scope={params?.scope}
                                    setDetailOpen={setDetailOpen}
                                    onClick={onClickItem}
                                    setDetailParams={setDetailParams}
                                /> : <ParagraphItem
                                    {...item}
                                    key={index}
                                    getEditor={getEditor}
                                    getEditorContents={getEditorContents}
                                    setEditorContents={setEditorContents}
                                    getEditorSelection={getEditorSelection}
                                    setTextSelection={setTextSelection}
                                    setTextReplace={setTextReplace}
                                />
                            ))
                                : <Nodata description="暂无搜索内容" />
                        }
                    </InfiniteScroll>

            )
        }

    }

    const onClose = () => {
        setDetailOpen(false)
    }

    return (
        <div className={styles.editorMaterial}>
            <div className={styles.materialHeader}>
                {/* <Segmented options={creationSearchScope} value={params?.scope} onChange={onChange} /> */}
                <Tabs activeKey={params?.scope} onChange={onChange}>
                    {
                        searchType?.map(item => {
                            return (
                                <Tabs.TabPane tab={item.label} key={item.value} />
                            )
                        })
                    }

                </Tabs>
            </div>
            {params.scope !== '2' && <div className={styles.searchInput}>
                <Input.Search placeholder="输入搜索内容" onSearch={handleSearch} value={params?.keyWord} onChange={onInputSearchText} />
            </div>}
            <div className={styles.listWrapper} ref={listWrapper}>
                {detailOpen ?
                    <Detail
                        detail={detail}
                        detailParams={detailParams}
                        getEditor={getEditor}
                        getEditorContents={getEditorContents}
                        setEditorContents={setEditorContents}
                        getEditorSelection={getEditorSelection}
                        setTextSelection={setTextSelection}
                        setTextReplace={setTextReplace}
                        onClose={onClose}
                        isVisible={params.scope == '1'?true:false}
                    />
                    :
                    renderList(params.scope)
                }
            </div>
            {/* <div className={styles.saveWrapper}>
                <SaveComponent />
            </div> */}
        </div>
    )
}
const CopyItem = ({
    id,
    content,
    onAppend,
    onImport,
    title,
    isVisible
}) => {
    const copyEle = useRef();
    const onCopy = () => {
        const ele = copyEle.current;
        const range = document.createRange();
        window.getSelection().removeAllRanges();
        range.selectNode(ele);
        window.getSelection().addRange(range);
        const copyStatus = document.execCommand("copy");
        if (copyStatus) {
            message.success("复制成功", 1);
        } else {
            message.error("复制失败", 1);
        }
        window.getSelection().removeAllRanges();
    }
    return (
        <div className={styles.paragraph} key={id}>
            <p ref={copyEle}>
                {content}
            </p>
            <div className={styles.action}>
                <span className={styles.actionItem} onClick={() => onAppend(content)}>
                    <Tooltip title="导入到写作区">
                        <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <i className='iconfont icon-zuojiantou'></i>
                            <span style={{ marginLeft: 4 }}>
                                导入
                            </span>
                        </span>
                    </Tooltip>
                </span>
              {
                isVisible&& 
                 (<span className={styles.actionItem} onClick={() => onImport({content,title})}>
                        <Tooltip title="导入素材库">
                            <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <i className='iconfont icon-zuojiantou'></i>
                                <span style={{ marginLeft: 4 }}>
                                导入素材库
                                </span>
                            </span>
                        </Tooltip>
                    </span>)
              }
                <span className={styles.actionItem} onClick={onCopy}>
                    <Tooltip title="复制">
                        <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <i className='iconfont icon-fuzhiwenjian'></i>
                            <span style={{ marginLeft: 4 }}>
                                复制
                            </span>
                        </span>
                    </Tooltip>
                </span>
            </div>
        </div>
    )
}
const Detail = ({
    detailParams,
    getEditor,
    getEditorContents,
    setEditorContents,
    getEditorSelection,
    setTextSelection,
    setTextReplace,
    detail,
    onClose,
    isVisible,
    ...props
}) => {



    /**
    * 文本追加
    * @param value 
    */
    const onAppend = (value) => {
        const range = getEditorSelection();
        setTextSelection(range, value)
    }
    /**
    * 导入素材库
    * @param value 
    */
    const onImport = async(params) => {
        const res =await searchImportResource(params)
        const {code,msg} =res.data
        if(code===200){
            message.success(msg)
        }else {
            message.error(msg)
        }
    }
    /**
     * 文本替换
     * @param value 
     */
    const onReplace = (value) => {
        setTextReplace(getEditorSelection(), value)
    }

    const handleClose = () => {
        onClose()
    }

    return (
        <div className={styles.detail}>
            <div className={styles.header}>
                <h4>
                    <span className={styles.title}>{detail?.title}</span>
                    <span className={styles.icon}><LeftCircleFilled onClick={handleClose} /></span>
                </h4>
                <div>{detail?.infoSource && '来源:' + detail?.infoSource}</div>
            </div>
            <div className={styles.paragraphList}>
                {
                    detail?.paragraphList.map((item, index) => <CopyItem id={index} content={item} onAppend={onAppend} key={index} onImport={onImport} title={detail?.title} isVisible={isVisible}/>)
                }
            </div>
        </div>
    )
}
// 如果是其他板块的时间轴
const TimelineItem = ({
    getEditor,
    getEditorContents,
    setEditorContents,
    getEditorSelection,
    setTextSelection,
    setTextReplace,
    onClick,
    ...props
}) => {
    return (
        <div className={styles.TimelineItem} onClick={() => onClick({ resourceId: props.resourceId, sketch: props.sketch, searchId: props.searchId })}>
            <div className={styles.lfetLine}>
                <div className={styles.circle}></div>
                <div className={styles.line}></div>
            </div>
            <div className={styles.timeAuthor}>
                <span>{props?.createTime}</span>
                <span>{props?.infoSource ? '｜' : ''}</span>
                <span>{props?.infoSource}</span>
            </div>
            <div className={styles.timelineContent}>
                <h4>{props?.title || props?.resourceName}</h4>
                <p>
                    {props?.sketch}
                </p>

            </div>

        </div>
    )
}
// 如果是精品稿件的时间轴
const TimelineItem3 = ({
    getEditor,
    getEditorContents,
    setEditorContents,
    getEditorSelection,
    setTextSelection,
    setTextReplace,
    onClick,
    ...props
}) => {

    return (
        <div className={styles.TimelineItem} onClick={() => onClick({ articleId: props.articleId, scope: props.scope })}>
            <div className={styles.lfetLine}>
                <div className={styles.circle}></div>
                <div className={styles.line}></div>
            </div>
            <div className={styles.timeAuthor}>
                <span>{props?.createTime}</span>
                <span>{props?.mediaName ? '｜' : ''}</span>
                <span>{props?.mediaName}</span>
            </div>
            <div className={styles.timelineContent}>
                <h4>{props?.articleTitle}</h4>
                <p dangerouslySetInnerHTML={{ __html: props.articleContent }}>
                </p>

            </div>

        </div>
    )
}
// 段落item
const ParagraphItem = ({
    content,
    getEditor,
    getEditorContents,
    setEditorContents,
    getEditorSelection,
    setTextSelection,
    setTextReplace,
}) => {
    const copyEle = useRef();
    const onCopy = () => {
        const ele = copyEle.current;
        const range = document.createRange();
        window.getSelection().removeAllRanges();
        range.selectNode(ele);
        window.getSelection().addRange(range);
        const copyStatus = document.execCommand("copy");
        if (copyStatus) {
            message.success("复制成功", 1);
        } else {
            message.error("复制失败", 1);
        }
        window.getSelection().removeAllRanges();
    }
    /**
   * 文本追加
   * @param value 
   */
    const onAppend = (value) => {
        const range = getEditorSelection();
        setTextSelection(range, value)
    }
    /**
     * 文本替换
     * @param value 
     */
    const onReplace = (value) => {
        setTextReplace(getEditorSelection(), value)
    }
    return (
        <div className={styles.paragraphItem}>
            <p ref={copyEle}>
                {content}
            </p>
            <div className={styles.action}>
                <span className={styles.actionItem} onClick={() => onAppend(content)}>
                    <Tooltip title="导入到写作区">
                        <i className='iconfont icon-youjiantou'></i>
                    </Tooltip>
                </span>
                <span className={styles.actionItem} onClick={() => onReplace(content)}>
                    <Tooltip title="替换">
                        <i className='iconfont icon-tihuan'></i>
                    </Tooltip>
                </span>
                <span className={styles.actionItem} onClick={onCopy}>
                    <Tooltip title="复制">
                        <i className='iconfont icon-fuzhi'></i>
                    </Tooltip>
                </span>
            </div>
        </div>
    )
}