import { Button, Descriptions, message, Modal, Tabs } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import style from "./index.module.less";
export default function App(props) {
	const { handleOk, isVisible, handleCancel, onLoad } = props;

	return (
		<Modal
			title={false}
			visible={isVisible}
			onOk={handleOk}
			onCancel={handleCancel}
			centered
			destroyOnClose={true}
			getContainer={false}
			footer={false}
			width={450}
		>
			<div className={style.content}>
				<div className={style.text}>
					<ExclamationCircleOutlined className={style.icon} />
					您的 “芯币” 不足，请您充值后尝试使用此功能
				</div>
				<div className={style.btn}>
					<Button type="primary" onClick={handleOk} style={{ marginLeft: 20 }} size="small">充值</Button>
					<Button size="small" onClick={handleCancel}>取消</Button>
				</div>
			</div>
		</Modal>
	);
}
