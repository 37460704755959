

import request from "@/service/request"

export function getGlobalEnum() {
    return request({
        url: `/module/common/dictionary/enum`,
        method: 'get',
    })
}
