// @ts-nocheck
import { Layout, Menu, Badge } from "antd";
import { FolderOpenFilled, HighlightFilled,FileOutlined  } from "@ant-design/icons";
import type { MenuProps } from "antd";
import { useLocation, useNavigate } from "react-router";
import { useEffect, useState, useContext } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { GlobalContext } from "@/GlobalContext";
import menuStyle from "./menu.module.less";
import { hasPermsAccess } from "@/access/AccessWrap";

const { Sider } = Layout;

type MenuItem = Required<MenuProps>["items"][number];

const BasicMenu: React.FC = () => {
	const { pathname } = useLocation();
	const {
		state: { user },
	} = useContext(GlobalContext);
	const [isNewWarnClick, setIsNewWarnClick] = useState(false);
	// 体验用户
	const isDisabled = user?.info?.permissionName === "EXPERIENCE" ? true : false;
	const navigate = useNavigate();
	const [key, setKey] = useState<string>(pathname);
	const MenuItems: MenuItem[] = [
		// {
		// 	title: "创作中心",
		// 	label: (
		// 		<span
		// 			style={{ margin: 0, width: "100%" }}
		// 			className={menuStyle.submenuTitle}
		// 			onClick={() => {
		// 				if (hasPermsAccess(user?.info?.permissionName, "creation.show")) return false;
		// 				setKey("/creation/list");
		// 				navigate("/creation/list");
		// 			}}
		// 		>
		// 			创作中心
		// 		</span>
		// 	),
		// 	disabled: hasPermsAccess(user?.info?.permissionName, "creation.show"),
		// 	key: "/creation/list",
		// 	icon: (
		// 		<HighlightFilled
		// 			onClick={() => {
		// 				if (hasPermsAccess(user?.info?.permissionName, "creation.show")) return;
		// 				setKey("/creation/list");
		// 				navigate("/creation/list");
		// 			}}
		// 		/>
		// 	),
		// },
		{
			title: "素材管理",
			label: (
				<span
					style={{ margin: 0, width: "100%" }}
					className={menuStyle.submenuTitle}
					onClick={() => {
						if (isDisabled) return;
						setKey("/material/resources");
						navigate("/material/resources");
					}}
				>
					素材管理
				</span>
			),
			key: "/material/resources",
			disabled: hasPermsAccess(user?.info?.permissionName, "material.click"),
			icon: (
				<FolderOpenFilled
					onClick={() => {
						if (hasPermsAccess(user?.info?.permissionName, "material.resources.show")) return;
						setKey("/material/resources");
						navigate("/material/resources");
					}}
				/>
			),
		},
		{
			title: "我的文稿",
			label: (
				<span
					style={{ margin: 0, width: "100%" }}
					className={menuStyle.submenuTitle}
					onClick={() => {
						if (isDisabled) return;
						setKey("/creation/doc");
						navigate("/creation/doc");
					}}
				>
					成文文稿
				</span>
			),
			key: "/creation/doc",
			disabled: hasPermsAccess(user?.info?.permissionName, "material.click"),
			icon: (
				<FileOutlined
					onClick={() => {
						if (hasPermsAccess(user?.info?.permissionName, "material.resources.show")) return;
						setKey("/creation/doc");
						navigate("/creation/doc");
					}}
				/>
			),
		},
	];

	useEffect(() => {
		console.log(pathname, "pathname");
		setKey(pathname);
	}, [pathname]);

	const onMenuSelect = ({ key }: { key: string }) => {
		setKey(key);
		navigate(key);
		if ((key = "/warn")) setIsNewWarnClick(true);
	};

	return (
		<Sider width="70" className={menuStyle.sider}>
			<Scrollbars>
				<Menu
					defaultSelectedKeys={[key]}
					selectedKeys={[key]}
					theme="light"
					mode="vertical"
					style={{ height: "100%" }}
					items={MenuItems}
					onSelect={onMenuSelect}
					onClick={({ item, key, keyPath, domEvent }) => {}}
				/>
			</Scrollbars>
		</Sider>
	);
};

const renderIcon = (Icon, access, path) => {
	const handleClick = () => {
		if (hasPermsAccess(user?.info?.permissionName, access)) return false;
		setKey(path);
		navigate(path);
	};
	return <Icon onClick={handleClick} />;
};

export default BasicMenu;
