import { Button, Dropdown, Menu, Modal, message, Badge, Tooltip, Space } from "antd";
import {
	UserOutlined,
	BellFilled,
	ExclamationCircleOutlined,
	ClockCircleOutlined,
	MoreOutlined,
} from "@ant-design/icons";
import { logout } from "@/pages/User/service";
import { useNavigate } from "react-router";
import { useContext } from "react";
import { GlobalContext } from "@/GlobalContext";
import { Link } from "react-router-dom";

const HeaderDropdownMenu = (props: any) => {
	const urlParams = new URL(window.location.href);
	const navigate = useNavigate();
	const {
		state: {
			user: { info },
		},
	} = useContext(GlobalContext);
	// 用户登出
	const handleLogout = async () => {
		localStorage.clear();
		navigate("/login", { replace: true });
		// try {
		// 	const response = await logout();
		// 	const { code, msg } = response.data;
		// 	if (code === 200) {
		// 		localStorage.clear();
		// 		navigate("/login", { replace: true });
		// 		return true;
		// 	} else {
		// 		message.error(msg);
		// 		return false;
		// 	}
		// } catch (error) {
		// 	console.log("Store Error", error);
		// 	return false;
		// }
	};

	const confirm = () => {
		Modal.confirm({
			title: "确认退出登录？",
			icon: <ExclamationCircleOutlined />,
			content: "",
			okText: "确认",
			cancelText: "取消",
			centered: true,
			onOk: () => {
				handleLogout();
			},
		});
	};

	const menu = (
		<Menu
			items={[
				{
					key: "1",
					label: (
						<span onClick={() => navigate("/user")} style={{ display: "block" }}>
							账号中心
						</span>
					),
				},
				{
					key: "2",
					label: (
						<span onClick={confirm} style={{ display: "block" }}>
							退出登录
						</span>
					),
				},
			]}
		/>
	);
	const menuUser = (
		<Menu
			items={[
				{
					key: "1",
					label: (
						<span onClick={() => navigate("/user_oriented")} style={{ display: "block" }}>
							用户引导
						</span>
					),
				},
			]}
		/>
	);
	return (
		<div style={{ display: "flex", alignItems: "center", marginRight: 20 }}>
			{/* <Tooltip title="通知中心">
				<Button
					type="link"
					style={{ marginRight: "10px" }}
					onClick={() => navigate("/notice")}
					icon={
						<Badge count={info.isNoticeRead || info.isNewWarn ? 1 : 0} offset={[0, 0]} dot>
							<BellFilled
								style={{ color: urlParams.pathname === "/notice" ? "#db5633" : "#fff", fontSize: 22 }}
							/>
						</Badge>
					}
				/>
			</Tooltip> */}
			<Dropdown overlay={menu} placement="bottom" arrow={{ pointAtCenter: true }}>
				<Button
					type="link"
					icon={
						<Badge
							count={info.isPerfectLogo || info.isPerfectMedia || info.isPerfectOrg ? 1 : 0}
							offset={[0, 0]}
							dot
						>
							{urlParams.pathname === "/user" ? (
								<span
									className="iconfont icon-denglu"
									style={{ cursor: "pointer", fontSize: 22, color: "#db5633" }}
								></span>
							) : (
								<UserOutlined style={{ color: "#fff", fontSize: 22 }} />
							)}
						</Badge>
					}
				/>
			</Dropdown>
		</div>
	);
};

export default HeaderDropdownMenu;
