import { useState, useEffect, forwardRef } from "react";
import { Button, Input, message, Form, Row, Col, Cascader } from "antd";
import { useForm } from "antd/lib/form/Form";
import styles from "./index.module.less"

export default forwardRef((props: any, ref) => {
    const [form] = useForm()
    // const [tagList, setTagList] = useState([])
    // 共享用户列表
    // const [monitorUser, setMonitorUser] = useState<any[]>([]);
    // const [userType, setUserType] = useState('1');

    const onFinish = (formValues) => {
        props.onFinish({
            ...formValues,
            folderId: formValues?.folderId
        })
    }

    // const setKeyWord = (val: string[]) => {
    //     setTagList([...val]);
    // };



    useEffect(() => {
        // 获取可共享用户列表
        // getCustomerList().then((response) => {
        //     const { data, msg, code } = response.data;
        //     if (code === 200) {
        //         setMonitorUser(data);
        //     } else {
        //         message.error(msg);
        //     }
        // });

    }, [])

    return (
        <div className={styles.saveComponent}>
            <Form
                name="basic"
                form={props.form}
                onFinish={onFinish}
                autoComplete="off"
                size="small"
                requiredMark={false}
            >
                <Row gutter={[10, 10]}>
                    <Col span={10}>
                        <Form.Item
                            label="文件名称"
                            name="resourceName"
                            rules={[{ required: false, message: '请输入文件名称!' }]}
                        >
                            <Input placeholder="请输入文件名称" />
                        </Form.Item>
                    </Col>
                    <Col span={10}>
                        <Form.Item
                            label="存储目录"
                            name="folderId"
                            rules={[{ required: false, message: '请选择存储目录!' }]}
                        >
                            <Cascader
                                placeholder="请选择上传目录"
                                options={props?.FolderList}
                                size="small"
                                fieldNames={{
                                    label: "resourceName",
                                    value: "resourceId",
                                    children: "resourceSubList",
                                }}
                                expandTrigger="hover"
                                changeOnSelect
                            ></Cascader>
                        </Form.Item>
                    </Col>
                    {/* <Col span={6}>
                        <Form.Item
                            label="文件密码"
                            name="accessCode"
                            rules={[{ required: false, message: '请输入文件密码!' }]}
                        >
                            <Input.Password placeholder="请输入文件密码" />
                        </Form.Item>
                    </Col> */}

                    <Col span={2}>
                       {props.buttonList}
                    </Col>
                    <Col span={2}>
                        <Form.Item style={{ margin: 0 }}>
                            <Button
                                type="primary"
                                htmlType="submit"
                            >保存</Button>
                        </Form.Item>
                    </Col>
                </Row>

                {/* <Row gutter={[10, 10]}>
                    <Col span={12}>
                        <Form.Item
                            label="文件密码"
                            name="accessCode"
                            rules={[{ required: false, message: '请输入文件密码!' }]}
                        >
                            <Input.Password placeholder="请输入文件密码" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="共享设置"
                            rules={[{ required: false }]}
                            name="userType"
                            initialValue="1"
                        >
                            <Select
                                onSelect={(v) => setUserType(v)}
                                options={shareTypeOptions}
                            ></Select>
                        </Form.Item>
                    </Col>
                </Row> */}
                {/* <Form.Item shouldUpdate style={{ display: userType === "3" ? 'unset' : 'none' }}>
                    {
                        () => {
                            return form.getFieldValue("userType") === '3' ?
                                <Form.Item name="shareUserList" style={{ marginBottom: 10 }}>
                                    <Select
                                        mode="multiple"
                                        placeholder="选择指定分享用户"
                                        options={monitorUser.map(item => ({ label: item.userName, value: item.userName }))}
                                    >
                                    </Select>
                                </Form.Item>
                                : null
                        }
                    }
                </Form.Item>
                <Row>
                    <Col span={24}>
                        <Form.Item
                            label="文件标签"
                            name="resourceTag"
                            rules={[{ required: false }]}
                        >
                            <CustomTextArea
                                setKeyWord={setKeyWord}
                                keyWord={tagList}
                                maxHeight={80}
                                isWordSplit={false}
                                tipType="标签"
                                placeholder="请输入文件标签"
                            />
                        </Form.Item>
                    </Col>
                </Row> */}

            </Form>
        </div>
    )
})
// const SaveComponent = (props) => {
//     const [form] = useForm()
//     // const [tagList, setTagList] = useState([])
//     // 共享用户列表
//     // const [monitorUser, setMonitorUser] = useState<any[]>([]);
//     // const [userType, setUserType] = useState('1');
//     const [FolderList, setFolderList] = useState<any>();

//     const onFinish = (formValues) => {
//         console.log(formValues, "formValues");
//         props.onFinish({
//             ...formValues,
//             folderId: formValues?.folderId[formValues?.folderId?.length - 1]
//         })
//     }

//     // const setKeyWord = (val: string[]) => {
//     //     setTagList([...val]);
//     // };

//     /**
//      * 获取文件夹list
//      * @returns 
//      */
//     const getFolderList = async () => {
//         try {
//             const res = await queryTree();
//             const { data, msg, code } = res.data;
//             if (code === 200) {
//                 setFolderList([...data]);
//             } else {
//                 message.error(msg);
//             }
//         } catch (error) { }
//     };

//     useEffect(() => {
//         // 获取可共享用户列表
//         // getCustomerList().then((response) => {
//         //     const { data, msg, code } = response.data;
//         //     if (code === 200) {
//         //         setMonitorUser(data);
//         //     } else {
//         //         message.error(msg);
//         //     }
//         // });
//         getFolderList()
//     }, [])

//     return (
//         <div className={styles.saveComponent}>
//             <Form
//                 name="basic"
//                 form={form}
//                 onFinish={onFinish}
//                 autoComplete="off"
//                 size="small"
//                 requiredMark={false}
//             >
//                 <Row gutter={[10, 10]}>
//                     <Col span={8}>
//                         <Form.Item
//                             label="文件名称"
//                             name="resourceName"
//                             rules={[{ required: false, message: '请输入文件名称!' }]}
//                         >
//                             <Input placeholder="请输入文件名称" />
//                         </Form.Item>
//                     </Col>
//                     <Col span={8}>
//                         <Form.Item
//                             label="存储目录"
//                             name="folderId"
//                             rules={[{ required: false, message: '请选择存储目录!' }]}
//                         >
//                             <Cascader
//                                 placeholder="请选择上传目录"
//                                 options={FolderList}
//                                 size="small"
//                                 fieldNames={{
//                                     label: "resourceName",
//                                     value: "resourceId",
//                                     children: "resourceList",
//                                 }}
//                                 expandTrigger="hover"
//                                 changeOnSelect
//                             ></Cascader>
//                         </Form.Item>
//                     </Col>
//                     <Col span={6}>
//                         <Form.Item
//                             label="文件密码"
//                             name="accessCode"
//                             rules={[{ required: false, message: '请输入文件密码!' }]}
//                         >
//                             <Input.Password placeholder="请输入文件密码" />
//                         </Form.Item>
//                     </Col>
//                     <Col span={2}>
//                         <Form.Item style={{ margin: 0 }}>
//                             <Button
//                                 type="primary"
//                                 htmlType="submit"
//                             >保存</Button>
//                         </Form.Item>
//                     </Col>
//                 </Row>

//                 {/* <Row gutter={[10, 10]}>
//                     <Col span={12}>
//                         <Form.Item
//                             label="文件密码"
//                             name="accessCode"
//                             rules={[{ required: false, message: '请输入文件密码!' }]}
//                         >
//                             <Input.Password placeholder="请输入文件密码" />
//                         </Form.Item>
//                     </Col>
//                     <Col span={12}>
//                         <Form.Item
//                             label="共享设置"
//                             rules={[{ required: false }]}
//                             name="userType"
//                             initialValue="1"
//                         >
//                             <Select
//                                 onSelect={(v) => setUserType(v)}
//                                 options={shareTypeOptions}
//                             ></Select>
//                         </Form.Item>
//                     </Col>
//                 </Row> */}
//                 {/* <Form.Item shouldUpdate style={{ display: userType === "3" ? 'unset' : 'none' }}>
//                     {
//                         () => {
//                             return form.getFieldValue("userType") === '3' ?
//                                 <Form.Item name="shareUserList" style={{ marginBottom: 10 }}>
//                                     <Select
//                                         mode="multiple"
//                                         placeholder="选择指定分享用户"
//                                         options={monitorUser.map(item => ({ label: item.userName, value: item.userName }))}
//                                     >
//                                     </Select>
//                                 </Form.Item>
//                                 : null
//                         }
//                     }
//                 </Form.Item>
//                 <Row>
//                     <Col span={24}>
//                         <Form.Item
//                             label="文件标签"
//                             name="resourceTag"
//                             rules={[{ required: false }]}
//                         >
//                             <CustomTextArea
//                                 setKeyWord={setKeyWord}
//                                 keyWord={tagList}
//                                 maxHeight={80}
//                                 isWordSplit={false}
//                                 tipType="标签"
//                                 placeholder="请输入文件标签"
//                             />
//                         </Form.Item>
//                     </Col>
//                 </Row> */}

//             </Form>
//         </div>
//     )
// }