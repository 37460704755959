import { message } from "antd";
import axios from "axios";
import { BASEURL as baseURL, TIMEOUT as timeout } from "./config";

const instance = axios.create({ baseURL, timeout });

instance.interceptors.request.use(
    config => {
        if (config.url.indexOf('/media_api') !== -1) config.baseURL = ''
        const userId = localStorage.getItem('userId');
        if (userId) if (config.headers) config.headers['userId'] = `${userId}`;
        return config
    },
    error => Promise.reject(error)

);

instance.interceptors.response.use(
    resp => {


        const { data } = resp;
        if (data.code === 401) {
            localStorage.clear()
            message.error(data.msg)
            setTimeout(() => { window.location.href = '/login' }, 1000)
        }
        return resp
    },
    error => {
        if (error.message == 'canceled') {
            return Promise.reject({msg:'已取消'})
        } else {
            return Promise.reject(error)
        }

    }

);

export default instance;



