import request from "@/service/request"
import type { loginParams, smsLoginParams, expLoginParams, smsCodeParams, probationParams, editSubjectParams, workParams,editUserInfoParams ,insertUserParams} from "./type"

// 获取小程序登录二维码
export function getQrcode() {
    return request({
        url: '/module/wechatMp/getQrcode',
        method: 'get'
    })
}
// 获取小程序登录二维码登录结果
export function getQrcodeResult(params) {
    return request({
        url: '/module/wechatMp/queryQrcodeResult',
        method: 'get',
        params
    })
}

// 短信验证码登录
export function smsLogin(params: smsLoginParams) {
    return request({
        url: '/module/login/mobile',
        method: 'post',
        data: params
    })
}

// 体验预览登入
export function expLogin(params: expLoginParams) {
    return request({
        url: '/auth/login/experienceDemo',
        method: 'post',
        params
    })
}

// 登出
export function logout() {
    return request({
        url: '/auth/login/logout',
        method: 'post',
    })
}

// 获取短信验证码
export function getSmsCode(params: smsCodeParams) {
    return request({
        url: '/module/login/captcha',
        method: 'post',
        data: params,
    })
}

// 获取短信验证码(不需要图片验证校验)
export function getSmsCodeNoCaptcha(params: smsCodeParams) {
    return request({
        url: '/account/customer/captcha',
        method: 'post',
        data: params,
    })
}

// 获取图片验证码
export function getImgCode() {
    return request({
        url: '/captchaImage',
        method: 'get',
    })
}

// 申请试用
export function getProbation(params: probationParams) {
    return request({
        url: '/auth/login/applyUse',
        method: 'post',
        data: params
    })
}

// 获取用户信息
export function getAccountInfo() {
    return request({
        url: '/auth/login/getAccountInfo',
        method: 'get',
    })
}

// 修改密码
export function updatePassWord(params) {
    return request({
        url: '/account/customer/updatePassWord',
        method: 'put',
        data: params
    })
}

// 更换手机号码
export function updateMobile(params) {
    return request({
        url: '/account/customer/updateMobile',
        method: 'put',
        data: params
    })
}

// 上传图片资源
export function uploadImgResource(params: { file: File }) {
    return request({
        url: '/base/resource/upload',
        method: 'put',
        data: params
    })
}

// 更新账户信息
export function updateAccountEntInfo(params: { file: File }) {
    return request({
        url: '/account/ent/updateAccountEntInfo',
        method: 'post',
        data: params
    })
}

// 查询所有媒体列表
export function findAllMediaInfo(params) {
    return request({
        url: '/recommend/mediaInfo/findAllMediaInfo',
        method: 'get',
        params,
    })
}

// 根据媒体名称模糊搜索
export function findMediaByMediaName(params) {
    return request({
        url: '/recommend/mediaInfo/findMediaByMediaName',
        method: 'get',
        params,
    })
}

// 关注
export function followMedia(params) {
    return request({
        url: '/account/attentionMedia/insert',
        method: 'post',
        data: params,
    })
}

// 取消关注
export function unFollowMedia(params) {
    return request({
        url: '/account/attentionMedia/deleteById',
        method: 'get',
        params,
    })
}

// 查询关注媒体列表
export function getFollowMediaList() {
    return request({
        url: '/account/attentionMedia/queryByToken',
        method: 'get',
    })
}

// 体验Demo
export function experienceDemo(params) {
    return request({
        url: '/auth/login/experienceDemo',
        method: 'post',
        data: params,

    })
}

// 创建主体
export function createSubject(params) {
    return request({
        url: '/account/entSubject/add',
        method: 'post',
        data: params,
    })
}

// 获取主体列表 table
export function getSubjectList(params) {
    return request({
        url: '/account/entSubject/page',
        method: 'post',
        data: params,
    })
}
// 获取主体列表 平行
export function getSubjectParallelList(params) {
    return request({
        url: '/account/entSubject/queryList',
        method: 'post',
        data: params,
    })
}
// 获取主体列表 树形
export function getSubjectListTree() {
    return request({
        url: '/account/entSubject/queryTree',
        method: 'post',
        data: {}
    })
}

// 更新主体
export function updateSubject(params: editSubjectParams) {
    return request({
        url: '/account/entSubject/edit',
        method: 'post',
        data: params,
    })
}

// 删除主体
export function deleteSubject(params) {
    return request({
        url: '/account/entSubject/delete',
        method: 'post',
        data: params,
    })
}

// 获取修改主体数据
export function getEditSubjectData(params: editSubjectParams) {
    return request({
        url: '/account/entSubject/get',
        method: 'get',
        params,
    })
}


// 添加工单
export function createWork(params: workParams) {
    return request({
        url: '/account/workOrder/insert',
        method: 'post',
        data: params,
    })
}
// 工单详情
export function getWorkInfo(params: { workOrderId: string }) {
    return request({
        url: '/account/workOrder/queryById',
        method: 'get',
        params,
    })
}
// 工单列表
export function getWorkList(params:{timeType:string,status:string,keyWord:string,timeList:string[],pageNum:number,pageSize:number}) {
    return request({
        url: '/account/workOrder/queryByPage',
        method: 'post',
        data:params
    })
}
// 删除工单
export function deleteWork(params: { workOrderId: string }) {
    return request({
        url: '/account/workOrder/deleteById',
        method: 'get',
        params: params,
    })
}
// 回复工单
export function createWorkReplay(params: { workOrderId: string }) {
    return request({
        url: '/account/workFeedback/insert',
        method: 'post',
        data: params,
    })
}

// 通知中心工单消息
export function getWorkNoticeList(params: { pageNum: number, pageSize: number, }) {
    return request({
        url: '/account/workFeedback/selectByPage',
        method: 'get',
        data: params,
    })
}

// 根据工单状态查询数量
// GET /account/workOrder/findCountByStatus
export function findCountByStatus() {
    return request({
        url: '/account/workOrder/findCountByStatus',
        method: 'get',
    })
}


// 查询账户管理列表
export function queryEntUserList() {
    return request({
        url: '/account/customer/queryEntUserList',
        method: 'get',
    })
}

// 重置密码
export function resetPassword(params:{customerId:number}) {
    return request({
        url: '/account/customer/resetPassword',
        method: 'get',
        params,
    })
}

// 管理员编辑用户
export function editUserInfo(params:editUserInfoParams) {
    return request({
        url: '/account/customer/editUserInfo',
        method: 'post',
        data:params
    })
}

// 管理员新增用户
export function insertUser(params:insertUserParams) {
    return request({
        url: '/account/customer/insertUser',
        method: 'post',
        data:params
    })
}
