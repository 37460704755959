import { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { Navigate } from "react-router";
import BasicLayout from "@/layout/BaseLayout";
import NotFound from "@/pages/NoFound";
import Login from "@/pages/User/Login";
import Loading from "@/components/Loading";
import Writing from "@/pages/Creation/Writing";
import type { RouteType } from "./data";

// 大纲
const Outline = lazy(() => import("@/pages/Creation/Outline"));
const MyDocument = lazy(() => import("@/pages/Creation/Document"));
const OutlineList = lazy(() => import("@/pages/Creation/OutlineList"));


const MaterialCollection = lazy(() => import("@/pages/Material/Collection"));
const MaterialResources = lazy(() => import("@/pages/Material/Resources"));
// 微信扫码文章详情
const WechatAuthorization = lazy(() => import("@/pages/WechatAuthorization"));
// 创作中心
const Creation = lazy(() => import("@/pages/Creation/List"));
const ShareManuscript = lazy(() => import("@/pages/ShareManuscript"));
const UserProfile = lazy(() => import("@/pages/User/UserProfile"));

const SusComp = (Component: any, key?: string) => (
	<Suspense fallback={<Loading />}>
		<Component key={key} />
	</Suspense>
);

const _basic = [
	{ path: "/login", name: "写作芯-登录", element: <Login /> },
	{ path: "/wechatAuthorization", name: "授权", element: SusComp(WechatAuthorization) },
	{ path: "/shareManuscript/:id", name: "分享", element: SusComp(ShareManuscript) },
	{ path: "*", element: <NotFound /> },
]

const _crearion=[
	{
		path: "/creation/list",
		name: "创作列表",
		element: SusComp(Creation),
	},
	{
		path: "/creation/writing",
		name: "创作中心",
		element: SusComp(Writing),
	},
	{
		path: "/creation/outline",
		name: "文章大纲",
		element: SusComp(Outline),
	},
	{
		path: "/creation/outlinelist",
		name: "大纲列表",
		element: SusComp(OutlineList),
	},
	{
		path: "/creation/doc",
		name: "我的文稿",
		element: SusComp(MyDocument),
	},
]

const _material = [
	{
		path: "/material/collection",
		name: "我的收藏",
		element: SusComp(MaterialCollection),
	},
	{
		path: "/material/resources",
		name: "素材管理",
		element: SusComp(MaterialResources),
	},
]

const _User = [
	{
		path: "/user",
		name: "账号信息",
		element: SusComp(UserProfile),
	},

]

const routes: RouteType[] = [
	{
		path: "/",
		name: "衍策云",
		element: <BasicLayout />,
		children: [
			// 资源库
			..._material,
			// 创作
			..._crearion,
			// 用户
			..._User,

			// {
			// 	path: "/",
			// 	name: "工作台",
			// 	element: <Navigate to="/creation/list" />,
			// },
			{
				path: "/",
				name: "我的文稿",
				element: <Navigate to="/creation/doc" />,
			},
			{ path: "*", element: <NotFound /> },
		],
	},
	..._basic,
];

/**
 * 处理标签页title
 * @param param
 * @returns
 */
const HandleTitle = ({ routeParams }: { routeParams: RouteType }) => {
	document.title = routeParams.name;
	return routeParams.element;
};
/**
 * load routes
 * @param rs
 * @returns
 */
export const renderRoutes = (rs: RouteType[]) => {
	return (
		<Routes>
			{rs.map((item) => {
				const { path, children } = item;
				return (
					<Route key={path} path={path} element={<HandleTitle routeParams={item} />}>
						{!!children &&
							children.map((i) => (
								<Route key={i.path} path={i.path} element={<HandleTitle routeParams={i} />} />
							))}
					</Route>
				);
			})}
		</Routes>
	);
};

export default routes;
