import {useState, useMemo, useCallback, useRef, useEffect,} from "react";
import {Layout, Input, Button, message, Modal, Space, Checkbox} from "antd";
import {LeftOutlined} from "@ant-design/icons";
import {useForm} from "antd/lib/form/Form";
import classnames from "classnames"
import ReactQuill, {Quill} from 'react-quill';
import {useLocation, useNavigate} from "react-router";
import {createCreationArticle, getCreationContent, checkSensitiveWords} from "@/pages/Creation/service"
import {getFileDirectory} from "@/pages/Material/service"
// import EditorLeftTemp from "./EditorLeftTemp";
// import CustomTextArea from "@/components/CustomTextArea";
// import TempLeftList from "./TempLeftList";
import EditorMaterial from "./EditorMaterial";
import Nodata from "@/components/Nodata";
// import { getCustomerList } from "@/pages/Monitor/service"
import EditorSave from "@/pages/Creation/Writing/EditorSave"
import "react-quill/dist/quill.snow.css"
import styles from "./index.module.less"
import moment from "moment";
import HintModal from "@/components/HintModal";
import TopUpModal from "@/components/TopUpModal";

const Delta = Quill.import('delta')

// const shareTypeOptions = [
//     { label: "仅自己", value: '1' },
//     { label: "所有人", value: '2' },
//     { label: "部分人", value: '3' },
// ];

export default function Writing() {
  const quillRef = useRef(null)
  let timerRef: any = useRef();
  let idRef = useRef();
  const titleRef = useRef('')
  const navigate = useNavigate()
  const [form] = useForm()
  const [textCount, setTextCount] = useState(0)
  const [FolderList, setFolderList] = useState<any>();
  const location: any = useLocation()
  const [title, setTitle] = useState('')
  const [flag, setFlag] = useState(false)
  const [updateTime, setUpdateTime] = useState('');
  // const [resourceName, setResourceName] = useState('')
  // const [resourceTag, setResourceTag] = useState([])
  // const [shareUserList, setShareUserList] = useState([])
  const [theme, setTheme] = useState<any>({})
  // const [TempListShowHide, setTempListShowHide] = useState('hide')
  // const [TempHide, setTempHide] = useState(false)
  const [SearchHide, setSearchHide] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [keyWordList, setKeyWordList] = useState([])
  const [checkedAll, setCheckedAll] = useState(false)
  // modal
  const [isTopUpVisible, setIsTopUpVisible] = useState<boolean>(false);
  const [isHintVisible, setIsHintVisible] = useState<boolean>(false);
  // 进入页面获取传入的主题ID 主题名称 主题描述
  const modules = useMemo(() => {
    return {
      toolbar: {container: '#toolbar'},
    }
  }, []);

  const Toolbar = useCallback(() => (
    <div id="toolbar" className={styles.tollbar}>
      <button className="ql-bold"></button>
      <button className="ql-italic"></button>
      <button className="ql-underline"></button>
      <button className="ql-strike"></button>
      <button className="ql-header" value="1"></button>
      <button className="ql-header" value="2"></button>
      <button className="ql-header" value="3">
        <svg className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5708">
          <path
            className="ql-fill"
            d="M938.666667 341.333333l-0.085334 85.333334-106.88 123.008a160 160 0 1 1-199.466666 184.917333l83.797333-16.298667a74.666667 74.666667 0 1 0 39.424-80.853333l-55.765333-66.005333L825.6 426.666667H640V341.333333h298.666667zM170.666667 170.666667v298.666666h298.666666V170.666667h85.333334v682.666666h-85.333334v-298.666666H170.666667v298.666666H85.333333V170.666667h85.333334z"
            p-id="5709">
          </path>
        </svg>
      </button>
      <button className="ql-list" value="ordered"></button>
      <button className="ql-list" value="bullet"></button>
      <button className="ql-direction" value="rtl"></button>
      <button className="ql-align" value="center"></button>
      <button className="ql-image"></button>
      <button className="ql-clean"></button>

    </div>
  ), []);

  /**
   * 控制左侧模版list
   * @param type
   */
  // const setLeftTempListShowHide = (type) => {
  //     setTempListShowHide(type)
  // }

  /**
   * 创建
   * @param params
   * @returns
   */
  const createWriting = async (params) => {
    try {
      const response = await createCreationArticle(params)
      const {code, msg, data} = response.data;
      if (code === 200) {
        // message.success(msg);
        return response.data;
      } else {
        message.error(msg)
        return false
      }
    } catch (error) {
      message.error(error)
      return false
    }
  }

  /**
   * 完成保存操作
   * @param formValues
   */
  const onFinish = async (formValues) => {
    const content = getEditorContents();
    // 去掉敏感词高亮
    const newContent = content.replace(/<span.*?style=".*?background-color.*?">(.*?)<\/span>/gi, '$1');
    console.log("手动保存", titleRef)
    const params = {
      ...formValues,
      dataSize: getTrimReplaceTextLenght() + "字",
      title: titleRef.current ? titleRef.current : "未命名",
      content: newContent,
      resourceId: idRef.current,
      resourceName: form.getFieldValue('resourceName') ? form.getFieldValue('resourceName') : '未命名',
      folderId: formValues.folderId ? formValues.folderId[formValues?.folderId?.length - 1] : 0,
      templateId: location?.state?.templateId,
      sourceType: "1"
    }
    if (location?.state?.resourceId) params.resourceId = location?.state?.resourceId;
    const result = await createWriting(params)
    if (result.code === 200) {
      setFlag(false)
      idRef.current = result.data.resourceId
      form.setFieldsValue({resourceName: form.getFieldValue('resourceName') ? form.getFieldValue('resourceName') : "未命名"})
      message.success(result.msg)
    }
  }

  /**
   * 获取实例
   * @returns
   */
  const getEditor = () => (quillRef?.current?.getEditor())

  /**
   * 获取内容
   * @returns
   */
  const getEditorContents = () => (quillRef?.current?.getEditorContents?.())

  /**
   * 设置内容
   * @param data
   */
  const setEditorContents = (data) => {
    quillRef?.current?.setEditorContents?.()
  }

  /**
   * 获取range
   * @returns
   */
  const getEditorSelection = () => (quillRef?.current?.getEditorSelection?.() ? quillRef?.current?.getEditorSelection?.() : getEditor().selection.savedRange)

  /**
   * 插入内容
   */
  const setTextSelection = (range, value) => {
    quillRef?.current.focus();
    // new
    const result = getEditor()?.clipboard.convert(value);
    if (range && result) {
      const delta = new Delta().retain(range.index).delete(range.length).concat(result);
      getEditor()?.updateContents(delta, 'user');
      let len = 0;
      delta.ops.forEach(op => {
        if (op.insert) {
          if (typeof op.insert === 'string') {
            len += op.insert.length;
          } else {
            len += 1;
          }
        }
      });
      setTimeout(() => {
        // 获取当前的光标位置
        const currentSelection = getEditor().getSelection();
        // 设置光标
        getEditor().setSelection(currentSelection?.index + len)
      }, 0)
    }
    // getEditor()?.insertText(index, text) old
  }

  /**
   * 替换内容
   * @param range
   * @param text
   */
  const setTextReplace = (range, value) => {
    // new
    const result = getEditor()?.clipboard.convert(value);
    if (range && result) {
      const delta = new Delta().retain(range.index).delete(range.length).concat(result);
      getEditor()?.updateContents(delta, 'user');
    }
    // old
    // getEditor()?.deleteText(range?.index, range?.length);
    // getEditor()?.insertText(range?.index, text);
  }

  /**
   * 点击2级主题
   * @param theme 主题
   */
  // const onClickSecondTheme = (theme) => {
  //     // 获取点击的theme
  //     setTheme(theme)
  //     // 隐藏templist
  //     setLeftTempListShowHide('hide')
  // }

  /**
   * 处理传入数据
   * @returns
   */
  const handleLocationParams = () => setTheme(location.state)

  const onFocus = () => {
    autoTimer()
    // timerRef.current = setInterval(() => {
    //     handleAutoSave()
    //     console.log(moment(Date.now()).format('YY-MM-DD hh:mm:ss'))
    // }, 5000)

  }

  const [count, setCount] = useState(60)

  const autoTimer = useCallback((flag = false) => {
    let _count = 60;
    timerRef.current && clearInterval(timerRef.current)
    timerRef.current = setInterval(() => {
      // 执行
      --_count;
      if (_count > 0) {
        setCount(_count);
      } else {
        handleAutoSave()
        console.log(moment(Date.now()).format('YY-MM-DD hh:mm:ss'))
        setCount(60)
        _count = 60;
      }
    }, 1000)
  }, [])

  const getTrimReplaceTextLenght = () => {
    return getEditor()?.getText()?.replace(/\r\n/g, "").replace(/\s*/g, "")?.length
  }

  //自动保存
  const handleAutoSave = async () => {
    // 获取内容
    const content = getEditorContents();
    // 获取保存文件信息
    const formValues = form.getFieldsValue()
    // 获取保存文件夹
    const {folderId} = formValues;
    // 去掉敏感词高亮
    const newContent = content.replace(/<span.*?style=".*?background-color.*?">(.*?)<\/span>/gi, '$1');

    // 格式化
    const updateParams = {
      ...formValues,
      dataSize: getTrimReplaceTextLenght() + "字",
      templateId: location.state?.templateId,
      title: titleRef.current ? titleRef.current : "未命名",
      content: newContent,
      resourceName: form.getFieldValue('resourceName') ? form.getFieldValue('resourceName') : "未命名",
      folderId: folderId ? folderId[folderId?.length - 1] : 0,
      resourceId: idRef.current,
      sourceType: "1"
    }
    const updateResponse: any = await createWriting(updateParams);
    if (updateResponse) {
      idRef.current = updateResponse.data.resourceId
      form.setFieldsValue({resourceName: form.getFieldValue('resourceName') ? form.getFieldValue('resourceName') : "未命名"})
      setUpdateTime(updateResponse.updateTime)
    }

  }
  /**
   * 获取文件夹list
   * @returns
   */
  const getFolderList = async () => {
    try {
      const res = await getFileDirectory();
      const {data, msg, code} = res.data;
      if (code === 200) {
        setFolderList([...data]);
        return true
      } else {
        message.error(msg);
        return false
      }
    } catch (error) {
      return false
    }
  };
  // 获取编译器编辑回显内容
  const getWriteContent = async (id) => {
    try {
      const response = await getCreationContent(id);
      const {data, code, msg} = response.data;
      if (code === 200) {
        setTitle(data?.title)
        titleRef.current = data?.title;
        setTextSelection({index: 0, length: 0}, data?.content)
        form.setFieldsValue({resourceName: data.resourceName})
        if (data.folderId != 0) form.setFieldsValue({folderId: data.folderIdList})

        setTextCount(getTrimReplaceTextLenght())

      } else {
        message.warn(msg)
      }
    } catch (error) {
      message.error(error)
    }
  }

  const showModal = async () => {
    const content = getEditorContents();
    if (content) {
      const res = await getCheckSensitiveWords({content: getEditorContents()})
      res && setIsModalOpen(true);
    }

  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const getCheckSensitiveWords = async (params) => {
    try {
      const resp = await checkSensitiveWords(params)
      const {data, code, msg} = resp.data;
      if (code === 200) {
        setKeyWordList(data.map(item => ({...item, isCheck: false})))
        message.success(msg);
        return true
      } else if (code == 5100) {
        setIsHintVisible(true)
        return false
      } else {
        message.warn(msg)
        return false
      }
    } catch (error) {
      message.warn(error)
      return false
    }
  }
  // 处理选中的敏感词
  const handleCheckedWord = (e, item) => {

    const list = keyWordList.map(k => {
      if (k.sensitiveWord === item.sensitiveWord) {
        return {...k, isCheck: e.target.checked}
      } else {
        return {...k}
      }
    })
    setKeyWordList(list)
  }
  //处理高亮敏感词
  const onShowInContent = () => {
    const isCheck = keyWordList.find(item => item.isCheck);
    if (!isCheck) return;
    const content = getEditorContents()
    const toStringList = keyWordList.filter(item => item.isCheck).map(k => k.sensitiveWord);
    const regListToString = toStringList.join('|');
    const newStr = content.replace(new RegExp(`(${regListToString})`, 'g'), `<span style="background:yellow">$1</span>`)
    setTextReplace({index: 0, length: 99999999999999}, newStr)
    setIsModalOpen(false);
  }
  // 处理选中所有
  const handleCheckedAll = (e) => {
    const list = keyWordList.map(k => {
      return {...k, isCheck: e.target.checked}
    })
    setCheckedAll(e.target.checked)
    setKeyWordList(list)
  }
  // 取消
  const onCancelShowInContent = () => {
    setIsModalOpen(false);
    setCheckedAll(false)
    setKeyWordList(keyWordList.map(item => ({...item, isCheck: false})))
  }
  // 关闭提示充值
  const hintModalCancel = () => {
    setIsHintVisible(false);
  };
  // 关闭提示充值 打开充值
  const hintModalOk = () => {
    setIsHintVisible(false);
    setIsTopUpVisible(true);
  };
  // 关闭充值
  const topUpCancel = () => {
    setIsTopUpVisible(false);
  };
  // 打开充值
  const topUpOk = () => {
    setIsTopUpVisible(true);
  };
  // useEffect(() => {
  //     if (location?.state) {
  //         handleLocationParams()
  //     }
  // }, [location.state])

  useEffect(() => {
    getFolderList()
    return () => {
      clearInterval(timerRef.current)
    }
  }, [])


  useEffect(() => {
    if (location?.state?.resourceId) {
      idRef.current = location?.state?.resourceId;
      getWriteContent(location?.state?.resourceId)
    }
    if (location?.state?.content) {
      setTextSelection({index: 0, length: 0}, location?.state?.content)
      setTextCount(getTrimReplaceTextLenght())
      setTitle(location?.state?.title)
      titleRef.current = location?.state?.title;
    }

  }, [])

  useEffect(() => {
    console.log(textCount, "发生变化");

  }, [textCount])

  useEffect(() => {
    if (title) titleRef.current = title;
  }, [title])

  useEffect(() => {
    console.log(location?.state, "location?.state?");

    if (keyWordList.filter(item => item.isCheck).length === keyWordList.length && keyWordList.length > 0) {
      setCheckedAll(true)
    } else {
      setCheckedAll(false)
    }
  }, [keyWordList])

  const pageCallBack = () => {
    navigate('/creation/doc', {
      state: {
        parentPage: location?.state?.parentPage,
        parentFolderId: location?.state?.parentFolderId,
        filePath: location?.state?.filePath
      }
    })
  };

  return (
    <Layout.Content className={styles.writing}>
      {/* 模版 */}
      {/* <div
                className={styles.templateModule}
                style={{
                    marginLeft: TempHide ? -400 : 0,
                    opacity: TempHide ? 0 : 1,
                }}
            >
                {TempListShowHide === 'hide' ?
                    <EditorLeftTemp
                        theme={theme}
                        setLeftTempListShowHide={setLeftTempListShowHide}
                        getEditor={getEditor}
                        getEditorContents={getEditorContents}
                        setEditorContents={setEditorContents}
                        getEditorSelection={getEditorSelection}
                        setTextSelection={setTextSelection}
                        setTextReplace={setTextReplace}
                    />
                    :
                    <TempLeftList
                        onClickSecondTheme={onClickSecondTheme}
                    />
                }
            </div> */}
      <HintModal handleCancel={hintModalCancel} handleOk={hintModalOk} isVisible={isHintVisible}/>
      <TopUpModal handleCancel={topUpCancel} handleOk={topUpOk} isVisible={isTopUpVisible}/>
      {/* 创作 */}
      <div className={styles.editorModule}>
        <div style={{height: '100%'}} className={styles.editorPanel}>
          <div className={styles.editorHeader}>
            {/* <Button icon={<LeftSquareFilled />} onClick={() => setTempHide(!TempHide)} style={{ color: TempHide ? '#06c' : '#333' }}></Button> */}
            {/* <ShowIcon type={TempHide} onClick={setTempHide} /> */}
            <Button type="text" icon={<LeftOutlined/>} onClick={pageCallBack}>返回</Button>
            <Toolbar/>
            <div className={styles.autoSave}>
              {/* <span>{updateTime && '保存于 - ' + updateTime}</span> */}
              <span style={{color: '#ccc'}}>{count}秒后自动保存</span>
            </div>
            {/* <Button icon={<LeftSquareFilled />} onClick={() => setSearchHide(!SearchHide)} style={{ color: SearchHide ? '#06c' : '#333' }}></Button> */}
            <ShowIcon type={SearchHide} onClick={setSearchHide} reverse={true}/>
          </div>
          <div className={styles.title}>
            <Input onChange={e => setTitle(e?.target?.value)} bordered={false} value={title} placeholder="请输入标题"
                   style={{background: '#f7f8ff'}}/>
          </div>
          <div className={styles.editorQuill} style={{height: 'calc(100% - 192px)'}}>
            <ReactQuill
              ref={quillRef}
              theme="snow"
              id="ql-editor"
              placeholder="点此输入"
              onChange={() => {
                quillRef.current.focus()
                const tc = getTrimReplaceTextLenght();
                setTextCount(tc)
              }}
              onFocus={onFocus}
              className={
                classnames({
                  [styles['editorNode']]: true,
                  'ql-editor': true,
                })
              }
              modules={modules}
            />
          </div>
          <div className={styles.btnModule}>
            <span className={styles.textCount}>当前字数:{textCount}字</span>
            <EditorSave
              onFinish={onFinish}
              form={form}
              FolderList={FolderList}
              buttonList={[
                <Button
                  type="default"
                  key="showModal"
                  onClick={showModal}
                >
                  敏感词检测
                </Button>
              ]}
            />
          </div>
        </div>
      </div>

      {/* 搜索 */}
      <div
        className={styles.searchModule}
        style={{
          marginRight: SearchHide ? -400 : 0,
          opacity: SearchHide ? 0 : 1,
        }}>
        <EditorMaterial
          getEditor={getEditor}
          getEditorContents={getEditorContents}
          setEditorContents={setEditorContents}
          getEditorSelection={getEditorSelection}
          setTextSelection={setTextSelection}
          setTextReplace={setTextReplace}
          templateId={location.state?.templateId}
          // SaveComponent={SaveComponent}
        />
      </div>

      <Modal title="敏感词检测" centered visible={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={false}>
        {keyWordList.length ? <div className={styles.wordList}>
          <Checkbox style={{marginLeft: 10}} checked={checkedAll} onChange={handleCheckedAll}>全选</Checkbox>
          <ul>
            {
              keyWordList.map((item, index) => {
                return <li key={index}>
                  <Checkbox
                    onChange={(e) => handleCheckedWord(e, item)}
                    checked={item.isCheck}
                  >
                    {item.sensitiveWord}
                  </Checkbox>
                  <span className={styles.type}>{item.sensitiveWordBigType}</span>
                </li>
              })
            }
          </ul>
        </div> : <Nodata description="未检测到敏感词"/>}
        {keyWordList.length ? <Space className={styles.btnList}>
          <Button type="default" size="small" onClick={onShowInContent}>在文中显示</Button>
          <Button type="primary" size="small" onClick={onCancelShowInContent}>取消</Button>
        </Space> : null}
      </Modal>
    </Layout.Content>

  )
}

const ShowIcon = (props) => {
  const LeftArrow = <svg style={{cursor: 'pointer'}} onClick={() => props.onClick(!props.type)} className="icon"
                         viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7777" width="20"
                         height="20">
    <path
      d="M109.632 673.664h519.68c25.152 0 45.568-22.016 45.568-48.896 0-26.88-20.416-48.896-45.568-48.896h-519.68c-25.216 0-45.632 22.016-45.632 48.896 0 26.88 20.48 48.896 45.632 48.896z m0-228.096h519.68c25.152 0 45.568-21.952 45.568-48.896 0-26.88-20.416-48.896-45.568-48.896h-519.68c-25.216 0-45.632 22.016-45.632 48.896 0 26.88 20.48 48.896 45.632 48.896z m3.264-219.904h795.776c26.88 0 50.56-20.352 51.328-47.168A48.896 48.896 0 0 0 911.104 128H115.328c-26.88 0-50.56 20.416-51.328 47.168a48.896 48.896 0 0 0 48.896 50.56z m619.776 447.232V348.672L960 510.784l-227.328 162.112c0 0.768 0 0.768 0 0z m178.432 122.944H115.328c-26.88 0-50.56 20.48-51.328 47.232a48.896 48.896 0 0 0 48.896 50.496h795.776c26.88 0 50.56-20.416 51.328-47.232a48.896 48.896 0 0 0-48.896-50.496z"
      p-id="7778"></path>
  </svg>
  const RightArrow = <svg style={{cursor: 'pointer'}} onClick={() => props.onClick(!props.type)} className="icon"
                          viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="9877"
                          width="20" height="20">
    <path
      d="M870.4 786.2784a51.2 51.2 0 1 1 0 102.4H153.6a51.2 51.2 0 0 1 0-102.4h716.8zM321.8432 347.4432v329.1136L102.4 512l219.4432-164.5568zM844.8 566.784a51.2 51.2 0 1 1 0 102.4h-358.4a51.2 51.2 0 0 1 0-102.4h358.4z m0-219.392a51.2 51.2 0 0 1 0 102.4h-358.4a51.2 51.2 0 1 1 0-102.4h358.4zM870.4 128a51.2 51.2 0 0 1 0 102.4H153.6a51.2 51.2 0 1 1 0-102.4h716.8z"
      p-id="9878"></path>
  </svg>
  if (props.reverse) {
    return (
      props.type ? RightArrow : LeftArrow
    )
  } else {
    return (
      props.type ? LeftArrow : RightArrow
    )
  }
}

