/**
 * 用户登录
 */
import { useState, useEffect, useCallback, useContext, useRef } from "react";
import { Navigate, useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { Layout, Button, Tabs, Form, Input, Image, message } from "antd";
import Loading from "@/components/Loading";
import { getSmsCode, getImgCode, getQrcode, getQrcodeResult } from "../service";
import { smsLogin } from "@/pages/User/service";
import { GlobalContext } from "@/GlobalContext";
import type { smsLoginParams } from "../type";
import loginStyle from "./login.module.less";

const { Header, Footer, Content } = Layout;
const { TabPane } = Tabs;
let $win: any = window;

const Login: React.FC = (props) => {
	const isLogin = localStorage.getItem("userId") !== null;
	const [formType, setFormType] = useState<string>("WechatLoginForm");
	const onChange = (key: string) => setFormType(key);
	const ref = useRef(null);
	useEffect(() => {
		if (ref.current) {
			$win.particlesJS("particles-js", {
				particles: {
					number: {
						value: 80,
						density: {
							enable: true,
							value_area: 700,
						},
					},
					color: {
						value: "#ffffff",
					},

					shape: {
						type: "circle",
						stroke: {
							width: 0,
							color: "#000000",
						},

						polygon: {
							nb_sides: 5,
						},
					},
					opacity: {
						value: 0.5,
						random: false,
						anim: {
							enable: false,
							speed: 0.1,
							opacity_min: 0.1,
							sync: false,
						},
					},
					size: {
						value: 3,
						random: true,
						anim: {
							enable: false,
							speed: 10,
							size_min: 0.1,
							sync: false,
						},
					},

					line_linked: {
						enable: true,
						distance: 150,
						color: "#ffffff",
						opacity: 0.4,
						width: 1,
					},

					move: {
						enable: true,
						speed: 2,
						direction: "none",
						random: false,
						straight: false,
						out_mode: "out",
						bounce: false,
						attract: {
							enable: false,
							rotateX: 600,
							rotateY: 1200,
						},
					},
				},
				interactivity: {
					detect_on: "canvas",
					events: {
						onhover: {
							enable: true,
							mode: "grab",
						},

						onclick: {
							enable: true,
							mode: "push",
						},

						resize: true,
					},
					modes: {
						grab: {
							distance: 140,
							line_linked: {
								opacity: 1,
							},
						},
						bubble: {
							distance: 400,
							size: 40,
							duration: 2,
							opacity: 8,
							speed: 3,
						},

						repulse: {
							distance: 200,
							duration: 0.4,
						},

						push: {
							particles_nb: 4,
						},

						remove: {
							particles_nb: 2,
						},
					},
				},
				retina_detect: true,
			});
		}
	}, []);

	if (!isLogin) {
		return (
			<div className={loginStyle.login} id="particles-js" ref={ref}>
				<div className={loginStyle.loginPosition}>
					<Header style={{ background: "unset", display: "flex", alignItems: "center", padding: "0 20px" }}>
						<img src="/images/logo.png" alt="" style={{ height: 30 }} />
					</Header>
					<Content className={loginStyle.loginContent}>
						<div className={loginStyle.loginForm}>
							<Tabs activeKey={formType} onChange={onChange} centered>
								<TabPane tab="微信登录" key="WechatLoginForm">
									{formType === "WechatLoginForm" && <WechatLoginForm key={formType} {...props} />}
								</TabPane>
								<TabPane tab="短信登录" key="MobileLoginForm">
									{formType === "MobileLoginForm" && <MobileLoginForm {...props} />}
								</TabPane>
							</Tabs>
						</div>
					</Content>
					<Footer style={{ textAlign: "center", background: "unset", color: "#fff" }}>
						{/* 含光素问 |{" "}
						<Link to="/probation" style={{ color: "#fff" }}>
							申请试用 |
						</Link>{" "}
						<Link to="/experience" style={{ color: "#fff" }}>
							体验Demo
						</Link>
						<br /> */}
						<a href="https://beian.miit.gov.cn/#/Integrated/index" style={{ color: "#f6f6f6" }}>
							京ICP备2022020525号
						</a>
					</Footer>
				</div>
			</div>
		);
	} else {
		return <Navigate to="/" />;
	}
};

// 微信登入
const WechatLoginForm = (props: any) => {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [Qrcode, setQrcode] = useState<string>("");
	const [scene, setScene] = useState<string>('')
	const qrcodeTimer = useRef<any>();
	const location = useLocation()
	// 字符串转arraybuffer
	function strToArrayBuffer(str) {
		var array = new Uint8Array(str.length);
		for (var i = 0; i < str.length; i++) {
			array[i] = str.charCodeAt(i);
		}
		return array.buffer
	}

	// 二维码
	const _getQrcode = async () => {

		try {
			setLoading(true)
			const response = await getQrcode();
			const { code, msg, data } = response.data;
			setLoading(false)
			if (code === 200) {
				const _bs64 = 'data:image/png;base64,' + data.qrcode;
				setQrcode(_bs64);
				setScene(data.scene)
			} else throw new Error(msg)
		} catch (error) {
			message.warn(error.msg);
		}
	};

	// 获取扫描结果
	const _getQrcodeResp = async () => {
		try {
			const response = await getQrcodeResult({ scene });
			const { code, msg, data } = response.data;
			if (code === 2001) getRespLoop()
			else if (code === 200) {
				localStorage.setItem('userId', data.userId);
				const state:any =location.state
				if(location.state){
					navigate(`/creation/writing`, {
						state: { content: state.content,title:state.title },
					});
				}else {
					navigate('/')
				}
			} else throw new Error(msg)
		} catch (error) {
			message.warn(error.msg);
		}
	}

	// 轮询
	const getRespLoop = () => {
		if (qrcodeTimer.current) {
			clearTimeout(qrcodeTimer.current);
			qrcodeTimer.current = null;
			qrcodeTimer.current = setTimeout(() => {
				_getQrcodeResp()
			}, 1500)
		} else {
			qrcodeTimer.current = setTimeout(() => {
				_getQrcodeResp()
			}, 1500)
		}
	}

	useEffect(() => {
		if (!localStorage.getItem("userId")) _getQrcode();
		else navigate("/");
		return ()=>{
			clearTimeout(qrcodeTimer.current)
		}
	}, []);

	useEffect(() => {
		if (scene) _getQrcodeResp()
	}, [scene])

	return (
		<div className={loginStyle.qrcode_box}>
			<div className={loginStyle.title}>快速安全登录</div>
			<div className={loginStyle.hint}>请使用微信扫描二维码登录</div>
			{loading ? <div className={loginStyle.loading}><Loading /></div> : <img src={Qrcode} alt="" />}
		</div>
	);
};

// 短信登入
const MobileLoginForm: React.FC = (props: any) => {
	const { state, dispatch } = useContext(GlobalContext);
	const navigate = useNavigate();
	const [form] = Form.useForm();
	const [captchaCode, setCaptchaCode] = useState<string>("");
	const [uuid, setUuid] = useState<string>("");
	const location = useLocation()

	// 短信登入
	const handleSmsLogin = async (values: smsLoginParams) => {
		// 普通账户登录-短息验证码登录
		const hide = message.loading("正在登录");
		try {
			const response = await smsLogin(values);
			hide();
			const { code, msg, data } = response.data;
			if (code === 200) {
				if (data.userId) {
					console.log("登录成功");
					localStorage.setItem("userId", data.userId);
					localStorage.setItem("isLogin", "true");
					localStorage.setItem("isFirstLogin", data.firstLogin + "");
					const state:any =location.state
					if(location.state){
						navigate(`/creation/writing`, {
							state: { content: state.content,title:state.title },
						});
					}else {
						navigate('/')
					}
					return data;
				} else {
					return false;
				}
			} else {
				getCaptchaCode(false);
				message.error(msg);
				return false;
			}
		} catch (error) {
			hide();
			console.log("Store Error", error);
			return false;
		}
	};
	// 倒计时
	const [countFlag, setCountFlag] = useState(false);
	const [count, setCount] = useState(60);

	// 处理倒计时
	const handleCount = useCallback(() => {
		let init: number = 60;
		let timer: any = null;
		if (timer) clearInterval(timer);
		timer = setInterval(() => {
			init -= 1;
			if (init > 0) {
				setCount(init);
			} else {
				clearInterval(timer);
				setCountFlag(false);
				setCount(60);
			}
		}, 1000);
	}, []);

	// 获取图形验证码
	const getCaptchaCode = async (isUnmounted: boolean) => {
		try {
			const response = await getImgCode();
			const { code, img, msg, uuid } = response.data;
			if (code === 200 && !isUnmounted) {
				// message.success(msg)
				const imgdata = `data:image/png;base64,${img}`;
				setCaptchaCode(imgdata);
				setUuid(uuid);
			} else {
				message.error(msg);
			}
		} catch (error) {
			console.log(error);
		}
	};

	// 获取短信验证码
	const handleGetSmsCode = async (isUnmounted: boolean) => {
		const mobile = form.getFieldValue("mobile");
		const imageCaptchaCode = form.getFieldValue("imageCaptchaCode");
		try {
			if (mobile) {
				if (imageCaptchaCode) {
					const hide = message.loading("正在获取");
					let response = await getSmsCode({ mobile, uuid, code:imageCaptchaCode });
					if (response.data.code === 200 && !isUnmounted) {
						hide();
						setCountFlag(true);
						message.success(response.data.msg);
						handleCount();
					}else{
						getCaptchaCode(false);
						message.warn(response.data.msg)
					}
				} else {
					message.error("请先输入图形验证码");
				}
			} else message.error("请先输入手机号");
		} catch (error) { }
	};

	// subimt
	const onMobileLogin = async (values: smsLoginParams) => {
		const params={
			mobile:values.mobile,
			mobileCaptcha:values.mobileCaptcha
		}
		
		let result = await handleSmsLogin(params);
		if (result.userId) {

		}
	};

	useEffect(() => {
		let isUnmounted = false;
		getCaptchaCode(isUnmounted);
		return () => {
			isUnmounted = true;
		};
	}, []);

	return (
		<Form
			name="MobileLoginForm"
			form={form}
			layout="horizontal"
			onFinish={onMobileLogin}
			autoComplete="off"
			labelCol={{ span: 4 }}
			wrapperCol={{ span: 20 }}
		>
			<Form.Item label="手机号" name="mobile" rules={[{ required: true, message: "请输入手机号!" }]}>
				<Input placeholder="请输入手机号码" />
			</Form.Item>

			<Form.Item
				label="校验码"
				name="imageCaptchaCode"
				rules={[{ required: true, message: "请输入图形校验码!" }]}
			>
				<Input
					placeholder="请输入图形校验码"
					addonAfter={
						<Image
							preview={false}
							src={captchaCode}
							className={loginStyle.captcha}
							onClick={() => getCaptchaCode(false)}
						/>
					}
				/>
			</Form.Item>

			<Form.Item label="验证码" name="mobileCaptcha" rules={[{ required: true, message: "请输入短信验证码!" }]}>
				<Input
					placeholder="请输入短信验证码"
					addonAfter={
						countFlag ? (
							<div style={{ color: "#999", width: "80px" }}>{count}秒</div>
						) : (
							<div style={{ width: "80px" }} onClick={() => handleGetSmsCode(false)}>
								发送验证码
							</div>
						)
					}
				/>
			</Form.Item>
			<div style={{ display: "flex", justifyContent: "center" }}>
				<Button
					style={{ marginTop: "10px", width: 200 }}
					type="primary"
					htmlType="submit"
					className="login-form-button"
				>
					登录
				</Button>
			</div>
		</Form>
	);
};

export default Login;
