import { Descriptions, message, Modal, Tabs } from "antd";
import { useEffect, useRef, useState } from "react";
import style from "./index.module.less";
import { wechatQrcode, getPayResult, getProductList } from "@/pages/User/UserProfile/service";
import QRCode from "qrcode"

export default function App(props) {
	const { handleOk, isVisible, handleCancel,onLoad } = props;
	const qrcodeTimer = useRef<any>();
	const { TabPane } = Tabs;
	const [productList,setProductList] = useState<any>([])
	const [qrcode, setQrcode] = useState<any>({
		codeUrl: "",
		orderNo: "",
	});
	const [query, setQuery] = useState({
		id: null,
		price: null,
	});
	const onChange = (props) => {
		setQuery({ ...query, id: props.productId, price: props.salesPrice });
	};
	
	// 查询商品套餐列表记录
	const getProductListHandler = async () => {
		const res = await getProductList();
		const { code, msg, data } = res.data;
		if (code == 200) {
			setProductList(data);
			setQuery({id:data?.[0].productId,price:data?.[0]?.salesPrice})
		} else {
			message.error(msg);
		}
	};
	// 获取支付二维码
	const wechatQrcodeHandler = async (params) => {
		const res = await wechatQrcode(params);
		const { code, data, msg } = res.data;
		if (code == 200) {
			QRCode.toDataURL(data?.codeUrl, (error: Error | null | undefined, url: string) => {
				setQrcode({ ...qrcode, codeUrl: url, orderNo: data.orderNo });
			});
		} else {
			message.error(msg);
		}
	};

	// 轮询
	const getRespLoop = (params) => {
		if (qrcodeTimer.current) {
			clearTimeout(qrcodeTimer.current);
			qrcodeTimer.current = null;
			qrcodeTimer.current = setTimeout(() => {
				getPayResultHandler(params);
			}, 2000);
		} else {
			qrcodeTimer.current = setTimeout(() => {
				getPayResultHandler(params);
			}, 2000);
		}
	};
	// 查询支付结果
	const getPayResultHandler = async (params) => {
		const res = await getPayResult(params);
		const { code, msg, data } = res.data;
		if (code == 200) {
			if(data.orderStatus==0){
				getRespLoop(params);
			}else {
				message.success("充值成功")
				handleCancel(qrcodeTimer)
				onLoad()
			}
		} else {
			message.error(msg)
		}
	};
	useEffect(() => {
		if(query.id==null)return
		wechatQrcodeHandler({ productId: query.id });
	}, [query]);
	useEffect(() => {
		if (isVisible) getPayResultHandler({orderNo:qrcode.orderNo});
		return clearTimeout(qrcodeTimer.current);
	}, [qrcode,isVisible]);
	useEffect(()=>{
		getProductListHandler()
	},[])

	return (
		<div className={style.box}>
			<Modal
				title={false}
				visible={isVisible}
				onOk={handleOk}
				onCancel={()=>handleCancel(qrcodeTimer)}
				centered
				destroyOnClose={true}
				getContainer={false}
				footer={false}
				width={800}
				bodyStyle={{ padding: 0 }}
				afterClose={()=>{clearTimeout(qrcodeTimer.current);}}
			>
				<div className={style.content}>
					<div className={style.left}>
						<div className={style.title}>芯币充值</div>
						<h4>功能说明:</h4>
						<div>
							<span>智能生成</span>
							<span>{`≤6芯币/次`}</span>
						</div>
						<div>
							<span>敏感词检测</span>
							<span>1芯币/次</span>
						</div>
						<div>
							<span>智能检索</span>
							<span>✓</span>
						</div>
						<div>
							<span>导入素材</span>
							<span>✓</span>
						</div>
						<div>
							<span>自动保存</span>
							<span>✓</span>
						</div>
					</div>
					<div className={style.right}>
						<div>
							<h4>选择套餐</h4>
							<div className={style.cardContent}>
								{productList?.map((item) => {
									return (
										<div
											className={item.productId === query.id ? style.active : style.card}
											key={item.id}
											onClick={() => onChange(item)}
										>
											<div>{item.coreMoney}芯币</div>
											<div>￥{item.salesPrice / 100}</div>
											{item.productId === query.id ? <img src="/images/active.png" /> : ""}
										</div>
									);
								})}
							</div>
						</div>
						<div>
							<h4>支付方式</h4>
							<div className={style.payContent}>
								<Tabs tabPosition="left" type="card" defaultActiveKey="1">
									<TabPane tab="微信" key="1">
										<div className={style.payCard}>
											<img src={qrcode.codeUrl} />
											<div className={style.card}>
												<div>
													支付金额：￥<span>{query.price / 100}</span>元
												</div>
												<div className={style.text}>请扫描二维码进行付款</div>
											</div>
										</div>
									</TabPane>
									<TabPane tab="支付宝" key="2" disabled={true}>
										Content of Tab 2
									</TabPane>
								</Tabs>
							</div>
						</div>
					</div>
				</div>
			</Modal>
		</div>
	);
}
