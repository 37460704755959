

import request from "@/service/request"
// 文章收藏列表
export function selectArticleCollectList(params) {
    return request({
        url: '/account/collect/selectArticleCollectList',
        method: 'post',
        data: params
    })
}
// 简报收藏列表
export function selectReportCollectList(params) {
    return request({
        url: '/account/collect/selectReportCollectList',
        method: 'post',
        data: params

    })
}
// 移动
export function moveFolder(params) {
    return request({
        url: '/module/resource/moveFolder',
        method: 'post',
        data: params
    })
}

// 分享简报
export function shareReportInfo(params: any) {
    return request({
        url: `/analyze/report-content/shareReportInfo`,
        method: 'post',
        data: params,
    })
}
// 下载文章word
export function articleExportWord(params: { docId: any }) {
    return request({
        url: '/account/collect/articleExportWord',
        method: 'get',
        params,
        responseType: 'arraybuffer',
    })
}
// 文章内容
export function getArticleDetail(params: { articleId: any }) {
    return request({
        url: '/recommend/article/get',
        method: 'get',
        params,
    })
}
// 收藏
export function addCollection(params: { collectId: string, collectType: string }) {
    return request({
        url: '/account/collect/insertYcCollectInfo',
        method: 'post',
        data: params
    })
}
// 取消收藏
export function cancelCollection(params: { collectId: string, collectType: string }) {
    return request({
        url: '/account/collect/updateYcCollectInfoByCollect',
        method: 'put',
        data: params
    })
}

// 查询文件夹列表
export function getResourceList(params: { folderId: number, page: number, size: number }) {
    return request({
        url: '/module/material/getResourceList',
        method: 'get',
        params: params
    })
}
// 新建文件夹
export function createFolder(params: { resourceName: string, folderId: number }) {
    return request({
        url: '/module/material/newFolder',
        method: 'post',
        data: params
    })
}
// 删除文件夹
export function deleteFolder(params: { idList: number[] }) {
    return request({
        url: '/module/resource/deleteResources',
        method: 'post',
        data: params
    })
}
// 编辑文件或者文件夹
export function editResource(params: { resourceId: number, resourceName: string }) {
    return request({
        url: '/module/resource/rename',
        method: 'post',
        data: params
    })
}
// 修改标签
export function editResourceTag(params: { resourceId: number, resourceName: string }) {
    return request({
        url: '/module/material/modifyResourceTag',
        method: 'post',
        data: params
    })
}
// 搜索
export function searchResources(params: { page: number, size: number, resourceName: string }) {
    return request({
        url: '/module/material/searchResources',
        method: 'post',
        data: params
    })
}
// 导入
export function uploadFile(params: { folderId: number, useType: string, encryptFlag: string, accessCode: string, resourceFile: File }) {
    return request({
        url: '/module/material/uploadFile',
        method: 'post',
        data: params
    })
}
// 导入文件夹
export function uploadFileList(params: { folderId: number, useType: string, encryptFlag: string, accessCode: string, resourceFiles: File }) {
    return request({
        url: '/module/material/batchUploadFile',
        method: 'post',
        data: params
    })
}
// 查看密码
export function viewCode(params: { resourceId: number }) {
    return request({
        url: '/base/resource/viewCode',
        method: 'get',
        params
    })
}
// 查看密码
export function updateCollectTag(params) {
    return request({
        url: '/account/collect/updateCollectTag',
        method: 'post',
        data: params
    })
}

// 查询文件夹树
export function queryTree(params?:any) {
    return request({
        url: `/module/material/queryTree`,
        method: 'get',
        params
    })
}
// 查询文件夹树
export function queryResourceIds(params) {
    return request({
        url: `/module/resource/queryResourceIds?resourceId=${params.resourceId}`,
        method: 'get',
    })
}

export function updateResources(params: { resourceId: number, shareUserList: any }) {
    return request({
        url: '/base/resource/modifyShareUser',
        method: 'post',
        data: params
    })
}

// 素材管理下载文件
export function downloadExportWord(params: { docId: any }) {
    return request({
        url: '/base/resource/download',
        method: 'get',
        params,
        responseType: 'arraybuffer',
    })
}

// 查询文稿文件夹树
export function getFileDirectory() {
    return request({
        url: `/module/czResource/getFileDirectory`,
        method: 'get',
    })
}