import request from "@/service/request";
import type { updataParams } from "./type";

// 查询用户信息
export function getUserInfo() {
	return request({
		url: "/module/user/query",
		method: "get",
	});
}

// 修改用户信息
export function updateUserInfo(params: updataParams) {
	return request({
		url: "/module/user/update",
		method: "post",
		data: params,
	});
}

// 查询商品套餐列表记录
export function getProductList() {
	return request({
		url: "/module/czProductInfo/getProductList",
		method: "get",
	});
}

// 查询消耗记录
export function getRecordList(params: { page: number; size: number }) {
	return request({
		url: "/module/consume/getRecordList",
		method: "post",
		data: params,
	});
}

// 查询订单列表
export function getOrderList(params: { page: number; size: number }) {
	return request({
		url: "/module/czOrderInfo/getOrderList",
		method: "post",
		data: params,
	});
}

// 生成支付二维码
export function wechatQrcode(params: { productId: string }) {
	return request({
		url: "/module/pay/wechatQrcode",
		method: "get",
		params,
	});
}

// 查询支付结果
export function getPayResult(params?: { orderNo?: string }) {
	return request({
		url: "/module/pay/payResult",
		method: "get",
		params,
	});
}

