import { Layout } from "antd";
import { useContext } from "react";
import { GlobalContext } from "@/GlobalContext";
import HeaderDropdownMenu from "../HeaderDropdownMenu";
import HeaderSearch from "../HeaderSearch";
import style from "./header.module.less";
import { Link } from "react-router-dom";
const { Header } = Layout;

const BasicHeader = () => {
	const {
		state: {
			user: { info },
		},
	} = useContext(GlobalContext);
	const url =  "/images/logo.png";
	// const url = info?.entDTO?.entLogoUrl ? info?.entDTO?.entLogoUrl : "/images/logo.png";

	return (
		<Header className={style.header}>
			<div className={style.logo}>
				<img src={url} alt="" style={{ height: 35 }} />
			</div>
			<div className={style.email}>
				服务邮箱：yancecloud@hanguangsw.com
			</div>
			<div className={style.icons}>
				<HeaderDropdownMenu />
			</div>
		</Header>
	);
};
export default BasicHeader;
